import { Box, Button, FormControl, FormControlLabel, MenuItem, Radio, RadioGroup, Select, TextField } from '@mui/material'
import { DateField, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { toggleLoader } from '../../Store/loader/loaderReducer';
import axios from 'axios';
import { toast } from 'react-toastify';

const defaultValue = dayjs();
// const defaultValueFormate = defaultValue.format('YYYY-MM-DD HH:mm:ss')

const monthMap = {
    1: 'January',
    2: 'February',
    3: 'March',
    4: 'April',
    5: 'May',
    6: 'June',
    7: 'July',
    8: 'August',
    9: 'September',
    10: 'October',
    11: 'November',
    12: 'December'
};
const weekMap = {
    'First': '1',
    'Secound': '2',
    'Third': '3',
    'Fourth': '4',
    'Last': '-1'
};

// Map for days to abbreviations
const dayMap = {
    'Sunday': 'SUN',
    'Monday': 'MON',
    'Tuesday': 'TUE',
    'Wednesday': 'WED',
    'Thursday': 'THU',
    'Friday': 'FRI',
    'Saturday': 'SAT'
};

const PostSettings = () => {

    const scheduleTypes = ["", "", "", "day", "Week", "Month", "Year"];
    const WeekDays = [
        { key: "Sunday", value: "S" },
        { key: "Monday", value: "M" },
        { key: "Tuesday", value: "T" },
        { key: "Wednesday", value: "W" },
        { key: "Thursday", value: "T" },
        { key: "Friday", value: "F" },
        { key: "Saturday", value: "S" }
    ];


    const [value, setValue] = useState({ repeatType: 3, })
    const [days, setDays] = useState(["Monday"])
    const [repeatInterval, setRepeatInterval] = useState(1)
    const [typeCount, setTypeCount] = useState("1")
    const [repeatOnMonthDay, setRepeatOnMonthDay] = useState("1")
    const [typeDate, setTypeDate] = useState(defaultValue)
    const [selectType, setSelectType] = useState("1")
    const [radioBtn, setRadioBtn] = useState('0')

    const [monthWeek, setMonthWeek] = useState("First")
    const [monthDays, setMonthDays] = useState("Sunday")
    const [month, setMonth] = useState(1)
    const [monthName, setMonthName] = useState("January")
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const org_info = useSelector((state) => state?.org_info?.value);
    const org_id = org_info?.default_organizations?.Id;

    const selectedValue = location?.state?.selectedValue

    const repeatOnDays = value.repeatType !== 5 ? days.map(key => WeekDays.findIndex(day => day.key === key) + 1) : monthWeek && monthDays ? `${weekMap[monthWeek]}${dayMap[monthDays]}` : '';


    const getLabelFirst = () => {
        return (
            <Box sx={{ display: 'flex', gap: '10px', }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>Every</Box>
                <Box sx={{ width: "80px" }}>
                    <TextField id="outlined-basic" variant="outlined"
                        sx={{ width: '80px', WebkitAppearance: "none" }} type='Number'
                        value={repeatInterval}
                        inputProps={{ min: 0, max: 99 }}
                        onChange={(e) => {
                            const value = Math.min(99, Math.max(0, e.target.value)); // Ensures value is between 0 and 99
                            setRepeatInterval(value);
                          }}
                         />
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>{scheduleTypes[value.repeatType]}(s) once</Box>
            </Box>
        )
    }

    const getLabelSecountCount = () => { //repeat_on_month_day
        return (
            <Box sx={{ display: 'flex', gap: '10px',paddingBottom:"10px" }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>On day</Box>
                <Box  sx={{ width: "80px" }}>
                    <TextField id="outlined-basic" variant="outlined"
                        sx={{ width: '80px', WebkitAppearance: "none" }} type='Number'
                        value={repeatOnMonthDay}
                        disabled={radioBtn==="3"}
                        
                        inputProps={{ min: 0, max: 31 }}
                        onChange={(e) => {
                            const value = Math.min(31, Math.max(0, e.target.value)); // Ensures value is between 0 and 99
                            setRepeatOnMonthDay(value);
                          }}
                        
                        />
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>day(s) once</Box>
            </Box>
        )
    }
    const getLabelSecount = () => {    //repeat_on_days
        return (
            <Box sx={{ display: 'flex', gap: '10px',gap: "10px"}}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>On the</Box>
                <Box>
                    <FormControl >
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={monthWeek}
                            disabled={radioBtn==="2"}
                            onChange={(e) => setMonthWeek(e.target.value)}
                        >
                            <MenuItem value={'First'}>First</MenuItem>
                            <MenuItem value={'Secound'}>Secound</MenuItem>
                            <MenuItem value={'Third'}>Third</MenuItem>
                            <MenuItem value={'Fourth'}>Fourth</MenuItem>
                            <MenuItem value={'Last'}>Last</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
                <Box>
                    <FormControl >
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={monthDays}
                            disabled={radioBtn==="2"}
                            onChange={(e) => setMonthDays(e.target.value)}
                        >
                            <MenuItem value={'Sunday'}>Sunday</MenuItem>
                            <MenuItem value={'Monday'}>Monday</MenuItem>
                            <MenuItem value={'Tuesday'}>Tuesday</MenuItem>
                            <MenuItem value={'Wednesday'}>Wednesday</MenuItem>
                            <MenuItem value={'Thursday'}>Thursday</MenuItem>
                            <MenuItem value={'Friday'}>Friday</MenuItem>
                            <MenuItem value={'Saturday'}>Saturday</MenuItem>

                        </Select>
                    </FormControl>
                </Box>
            </Box>
        )
    }
    const handleDay = (value) => {
        if (days.includes(value)) {
            // If "Monday" is in the array, remove it
            setDays(days.filter((day) => day !== value));
        } else {
            // If "Monday" is not in the array, add it
            setDays([...days, value]);
        }
    }

    const handleSubmit = (val) => {

        let data = {
            post_info: selectedValue && selectedValue,
            repeat_type: value.repeatType,//days constant is 3
            repeat_interval: repeatInterval, // every day(s) once parm
            org_id: org_id,
            post_type: val, // post type
            repeat_on_month_day: repeatOnMonthDay,
            repeat_on_days: repeatOnDays,

        };

        (value.repeatType !== 4 && value.repeatType !== 5) && delete data.repeat_on_days;
        value.repeatType !== 5 && delete data.repeat_on_month_day
        radioBtn === '2' && delete data.repeat_on_days;
        radioBtn === '3' && delete data.repeat_on_month_day;

        dispatch(toggleLoader(true));
        axios
            .post(process.env.REACT_APP_BLOG_REQUEST, data, {
                withCredentials: process.env.REACT_APP_CREDENTIALS,
            })
            .then((res) => {
                dispatch(toggleLoader(false));
                toast.success('Success');
            })
            .catch((err) => {
                toast.error(err.message);
                dispatch(toggleLoader(false));
            });
    }
    const handlesetMonth = (e) => {
        const selectedValue = e.target.value;
        setMonth(selectedValue);
        setMonthName(monthMap[selectedValue]);
    }

    return (

        <Box sx={{ padding: '20px' }}>
            <Box sx={{ color: 'var(--header-color)', paddingBottom: '20px', fontSize: '22px', fontWeight: 'bold', borderBottom: '1px solid var(--border-color)' }}>Post Settings</Box>
            <Box sx={{ padding: "10px 0px" }}><Button variant="outlined" onClick={() => navigate(`/c${org_id}/related-questions`)}>Back</Button></Box>
            <Box >
                <Box sx={{ border: " 1px solid var(--border-color)", borderRadius: '5px', }}>
                    <Box sx={{ fontSize: '18px', fontWeight: 'bold', color: 'var(--table-header-font)', borderBottom: "1px solid var(--border-color)", padding: '10px 15px ' }}>Repeat Event</Box>
                    <Box sx={{ display: 'flex', gap: '10px', padding: '10px 15px ' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>Repeat</Box>
                        <Box>
                            <FormControl>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={value.repeatType}
                                    onChange={(e) => {
                                        setValue({ ...value, repeatType: e.target.value });
                                        setRadioBtn((e.target.value === 5 || e.target.value === 6) ? '2' : '0');
                                    }}
                                >
                                    <MenuItem value={3}>Daily</MenuItem>
                                    <MenuItem value={4} >Weekly</MenuItem>
                                    <MenuItem value={5} >Monthly</MenuItem>
                                    {/* <MenuItem value={6}>Yearly</MenuItem> */}
                                </Select>
                            </FormControl></Box>
                    </Box>
                    {value.repeatType !== 3 && <Box sx={{ display: 'flex', gap: '10px', padding: '10px 15px ', }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>Every</Box>
                        {value.repeatType !== 6 ? <Box sx={{ width: '80px' }}>
                            <TextField id="outlined-basic" variant="outlined"
                                sx={{ width: '80px' }} type='Number'
                                value={repeatInterval}
                                inputProps={{ min: 0, max: 99 }}
                                onChange={(e) => {
                                    const value = Math.min(99, Math.max(0, e.target.value)); // Ensures value is between 0 and 99
                                    setRepeatInterval(value);
                                  }}
                                 
                                 />
                        </Box> : <Box>
                            <FormControl >
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={month}
                                    onChange={(e) => handlesetMonth(e)}
                                // label="Age"
                                // onChange={(e) => setMonth(e.target.value)}
                                >
                                    <MenuItem value={1} >January</MenuItem>
                                    <MenuItem value={2} >February</MenuItem>
                                    <MenuItem value={3} >March</MenuItem>
                                    <MenuItem value={4} >April</MenuItem>
                                    <MenuItem value={5} >May</MenuItem>
                                    <MenuItem value={6} >June</MenuItem>
                                    <MenuItem value={7} >July</MenuItem>
                                    <MenuItem value={8} >August</MenuItem>
                                    <MenuItem value={9} >September</MenuItem>
                                    <MenuItem value={10} >October</MenuItem>
                                    <MenuItem value={11} >November</MenuItem>
                                    <MenuItem value={12} >December</MenuItem>
                                </Select>
                            </FormControl>

                        </Box>}
                        {value.repeatType !== 6 && <Box sx={{ display: 'flex', alignItems: 'center' }}>{scheduleTypes[value.repeatType]}(s) once</Box>}
                    </Box>}
                    <FormControl sx={{ padding: '10px 15px ' }}>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            value={radioBtn}
                            name="radio-buttons-group"
                            onChange={(e) => { setRadioBtn(e.target.value) }}
                        >
                            {value.repeatType === 3 && <FormControlLabel value={0} control={<Radio />} label={getLabelFirst()} />}
                            {/* {value.repeatType=== 3 && <FormControlLabel value={1} control={<Radio />} label="Week days only" />} */}
                            {(value.repeatType === 5 || value.repeatType === 6) && <FormControlLabel value={2} control={<Radio />} label={getLabelSecountCount()} />}
                            {(value.repeatType === 5 || value.repeatType === 6) && <FormControlLabel value={3} control={<Radio />} label={getLabelSecount()} />}
                        </RadioGroup>
                    </FormControl>
                    {value.repeatType === 4 &&
                        <Box sx={{ display: 'flex', gap: '10px', padding: '10px 15px ' }}>
                            {WeekDays?.map((day, key) => (
                                <Box
                                    // onClick={() => setDays(day.key)}
                                    onClick={() => handleDay(day.key)}
                                    sx={{
                                        width: '30px',
                                        height: '30px',
                                        borderRadius: '50%',
                                        backgroundColor: days.includes(day.key) ? '#3498db' : '#ffff',
                                        boxShadow: 'var(--box-shadow)',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        fontSize: '20px',
                                        cursor: 'pointer',
                                        color: days.includes(day.key) ? 'white' : 'black',
                                    }}
                                >{day.value} </Box>))}
                        </Box>
                    }
                    <Box sx={{ display: 'flex', gap: '10px', padding: '10px 15px ' }}>
                        {/* <Box sx={{ display: 'flex', alignItems: 'center' }}>Ends</Box>
                        <Box>
                            <FormControl >
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={selectType}
                                    // label="Age"
                                    onChange={(e) => setSelectType(e.target.value)}
                                >
                                    <MenuItem value={1}>Never</MenuItem>
                                    <MenuItem value={2}>By Date</MenuItem>
                                    <MenuItem value={3}>By Count</MenuItem>
                                </Select>
                            </FormControl>

                        </Box> */}
                        {selectType === 2 && <Box >
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={['DateField']}>
                                    <DemoItem>
                                        <DateField
                                            value={typeDate}
                                            onChange={(e) => setTypeDate(e)}
                                            format="DD-MM-YYYY" />
                                    </DemoItem>
                                </DemoContainer>
                            </LocalizationProvider>
                        </Box>}
                        {selectType === 3 && <Box>
                            <TextField id="outlined-basic" variant="outlined" defaultValue="1" sx={{ width: '50px' }}
                                type='Number'
                                onChange={(e) => setTypeCount(e.target.value)}
                            /></Box>}
                    </Box>
                    <Box sx={{ padding: '10px 15px ' }}>
                        {radioBtn === '0' ? <Box sx={{ background: '#ffeed2', width: '50%', borderRadius: '5px' }}>
                            <Box sx={{ padding: '10px' }}>Every {repeatInterval} {scheduleTypes[value.repeatType]}
                                {selectType === 3 && ` For ${typeCount} time`}
                                {selectType === 2 && ` Until ${typeDate.format("DD-MM-YYYY")} `} {value.repeatType === 4 && days?.join(', ')}
                            </Box>
                        </Box> :
                            radioBtn === '1' ? <Box sx={{ background: '#ffeed2', width: '50%', borderRadius: '5px' }}>
                                <Box sx={{ padding: '10px' }}>
                                    Every Weekday
                                    {selectType === 3 && ` For ${typeCount} time`}
                                    {selectType === 2 && ` Until ${typeDate.format("DD-MM-YYYY")} `}
                                </Box>
                            </Box> : <Box sx={{ background: '#ffeed2', width: '50%', borderRadius: '5px' }}>
                                <Box sx={{ padding: '10px' }}>
                                    Every {value.repeatType === 6 ? monthName : "Month"} on {radioBtn === '3' ? `${monthWeek} ${monthDays}` : `day ${repeatOnMonthDay}`}
                                    {selectType === 3 && ` For ${typeCount} time`}
                                    {selectType === 2 && ` Until ${typeDate.format("DD-MM-YYYY")} `}
                                </Box>
                            </Box>
                        }
                    </Box>
                    <Box sx={{ display: 'flex', gap: '10px', padding: '10px 15px', justifyContent: " flex-end" }}>
                        <Button variant="outlined" onClick={() => handleSubmit(1)}> Draft</Button>
                        <Button variant="contained" onClick={() => handleSubmit(2)}> Publish</Button>
                        <Button variant="contained" onClick={() => handleSubmit(3)}> Send for review</Button>
                    </Box>
                </Box>
            </Box>

        </Box>
    )
}

export default PostSettings